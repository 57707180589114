.intro__container {
  min-height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro__content {
  display: flex;
  flex-flow: row nowrap;
}
.intro__text {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.intro__text h1 {
  font-family: var(--primary-font);
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-font);
}
.intro__img {
  flex: 1;
  height: 380px;
  border-radius: 50%;
  background-image: url("../../../../assets/images/132726218_1896005157225297_7414236719923744195_n.jpg");
  background-repeat: no-repeat;
  /* background-position: right 30% bottom 45%; */
  background-size: cover;
}
.tech__content {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 36px;
}
.tech__menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.tech__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row nowrap;
}
.tech__item {
  width: 36px;
  height: 36px;
  margin: 0 24px;
}
.tech__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
