.button {
  padding: 8px 36px;
  border: 1px solid var(--primary-color);
  border-radius: 24px;
  background-color: white;
}
.button + .button {
  margin-left: 16px;
}
.button:hover {
  border-color: var(--secondary-color);
}
.button a {
  text-decoration: none;
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
  letter-spacing: 0.2px;
}
.button:hover a {
  color: var(--secondary-color);
}
