* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
:root {
  --primary-font: "DM Serif Display", serif;
  --secondary-font: "Jost", sans-serif;
  --primary-color: #292f36;
  --secondary-color: #cda274;
}
.col-2 {
  width: calc(100% / 2);
}
.col-4 {
  width: calc(100% / 4);
}
.col-8 {
  width: calc(100% / 8);
}
